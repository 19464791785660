/* -----------------------------------------------------------------------------------------
    THSI FILE CONTAINS THE IMPORTANT WEB3/UI CONFIGURATIONS FOR THE APP
----------------------------------------------------------------------------------------- */

export const appSettings = {
    /* APP LOGO - LOGO FOR THE DARK VERSION  */
    logo: '/logo.png',

    /* APP MAIN LOGO WIDTH - CHANGE IT ACCORDING TO YOUR OWN LOGO */
    logoWidth: '100',

    /* APP BRAND NAME - USED IN ALL PAGES & META TAGE - CHANGE TO YOUR OWN BRAND NAME*/
    brandName: 'Restifi',

    /* APP BRAND DESCRIPTION - USED IN HOME PAGE & META TAGS - PUT A SENTENCE THAT DESCRIBE YOUR APP*/
    brandDescription: 'Tokenized equity marketplace',

    /* APP AUTHOR - CHANGE IT IF YOU HAVE A LICENSE KEY OR PURCHASE CODE */
    appAuthor: '',

    /* COPYRIGHTS BRAND NAME */
    copyrightsBrand: '',

    /* TEXT IDENTIFIER FOR THE NETWORK WHERE THE APP IS UP AND RUNNING, YOU CAN WRITE WHATEVER YOU WANT */
    activeNetworkName: 'Polygon Network',

    /* COPYRIGHTS LINK - REPLACE IT IF YOU HAVE A LICENSE KEY OR A PURCHASE CODE */
    copyrightsLink: '',

    /* THE NETWORK RPC URL WHERE YOUR CONTRACTS ARE DEPOLYED ON, CHECK THE EXACT RPC URL FROM HERE https://chainlist.org */
    rpcUrl: 'https://polygon-mainnet.g.alchemy.com/v2/6DCPfZzyjJSzyS_AT8xtye3mk-J-kBJh',

    /* THE CHAIN ID of NETWORK WHERE YOUR CONTRACTS ARE DEPOLYED ON, GET IT FROM HERE https://chainlist.org */
    networkId: 137,

    /* THE BLOCK SCAN EXPLORER WHRE YOU CAN TRACK THE TRANSACTIONS */
    blockExplorerUrl: 'https://polygonscan.com/',

    /* THE CURRENT APP CURRENCY THAT YOUR APP WILL APPEAR BESIDES EVERY PRICE */
    currency: 'MATIC',

    /* THE NATIVE CURRENCY THAT YOUR APP WILL USE FOR GAS FEES */
    nativeCurrency: 'MATIC',

    /* IPFS PROJECT ID */
    IPFSProjectID: '2XchY391JNX20ZfvpuOMcwxaH7z',

    /* IPFS PRJECT SECRET KEY */
    IPFSSecret: '96e73deb0ab95a1079ca6b2d06fbbce3',

    /* IPFS DEDICATED GATEWAY NAME */
    IPFSGatewaySubdomain: 'restifi',

    /* ENDPOINT FOR ASK FOR A FEATURE FORM */
    newsletterFormId: 'mrgwvqrn',

    /* WALLET CONNECT PROJECT ID */
    wcProjectId: '4a4906419525a76575436632620100ca',

    /* MAX PROJECT DURATION IN DAYS */
    maxDuration: 365,
};
