import React from 'react';
import { Web3Button } from '@web3modal/react';
import {
    useConnect,
  } from "wagmi";

function ConnectWalletHander() {
    const { connect, connectors } = useConnect();
    return (
        <>
           <button
                    className='btn btn-primary w-100 mt-3'
                    style = {{ borderRadius: '15px'}}
                    onClick={() => connect({ connector: connectors[0] })}
                  >
                    Sign in
                  </button>
        </>
    );
}

export default ConnectWalletHander;
