import React from 'react';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import useUser from '../../hooks/useUser';
import PendingProjects from './PendingProjects';
import UserActiveProjects from './UserActiveProjects';
import UserSuccessfulProjects from './UserSuccessfulProjects';
import InvolvedProjects from './ProjectsInvolved';
import UserFailedProjects from './UserFailedProjects';
import UserStats from './UserStatus';
import WaitingForApproval from './WaitingForApproval';

function AccountPage() {
    const { userInfo } = useUser();

    return (
        <>
            <PageBanner
                heading='My Account'
                text='Your centralized hub to manage investments, view transaction history, and personalize account settings'
            ></PageBanner>
            <section className='pb-5 page-first-section'>
                <div className='container pb-5'>
                    <header className='mb-5 text-center'>
                        <h2 className='mb-0'>Hi {userInfo?.name}</h2>
                        <p className='text-muted'>Here're some stats about your account</p>
                    </header>
                    <div className='mb-3'>
                        <UserStats />
                    </div>

                    {/* <div className='mb-3'>
                        <WaitingForApproval />
                    </div> */}
                    {/* <div className='mb-3'>
                        <PendingProjects />
                    </div>
                    <div className='mb-3'>
                        <UserActiveProjects />
                    </div>
                    <div className='mb-3'>
                        <UserSuccessfulProjects />
                    </div> */}
                    <div className='mb-3'>
                        <InvolvedProjects />
                    </div>
                    {/* <div className='mb-3'>
                        <UserFailedProjects />
                    </div> */}
                </div>
            </section>
        </>
    );
}

export default AccountPage;
