import React, { useState } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { categories } from '../../helpers/constants';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';
import useProject from '../../hooks/useProject';

// COMPONENTS
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';

// IPFS CREATE HOST
const auth = 'Basic ' + Buffer.from(appSettings.IPFSProjectID + ':' + appSettings.IPFSSecret).toString('base64');
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

function ProjectForm() {
    const { account } = useWeb3();
    const navigate = useNavigate();
    const { setTransactionLoading } = useApp();
    const { allProjects, projectContract, projectContractAbi, loadAllProjects } = useProject();
    const [submit, setSubmit] = useState(false);
    const {
        register,
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
          LAUNCH THE PROJECT HANDLER
    --------------------------------------------- */
    const { write: web3CreateProject } = useContractWrite({
        address: projectContract?.address,
        abi: projectContractAbi,
        functionName: 'launch',
        onMutate() {
            setTransactionLoading(true);
        },
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                toast.success('You have sucessfully created a project');
                loadAllProjects(projectContract, projectContractAbi);
                navigate('/account');
                setSubmit(true);
            }, 5000);
        },
        onError() {
            setSubmit(false);
            setTransactionLoading(false);
            toast.error('Something went wrong');
        },
    });

    /* --------------------------------------------- 
          HANDLE REGISTER FORM SUBMIT
    --------------------------------------------- */
    async function handleFormSubmit(data) {
        setTransactionLoading(true);
        setSubmit(true);

        const ipfsCoverImage = await ipfs.add(data?.image[0]);

        console.log('NEW PRICE', Web3.utils.toWei(data?.goal.toString(), 'gwei'));

        if (ipfsCoverImage) {
            web3CreateProject({
                args: [
                    Web3.utils.toWei(data?.goal.toString(), 'gwei'),
                    Math.ceil(new Date(data?.start_at).getTime() / 1000 + 30),
                    Math.ceil(new Date(data?.end_at).getTime() / 1000),
                    data?.title,
                    data?.description,
                    data?.category?.label,
                    `https://${appSettings.IPFSGatewaySubdomain}.infura-ipfs.io/ipfs/${ipfsCoverImage.path}`,
                ],
            });
        } else {
            toast.error('Something went wrong during uploading the image');
        }
    }

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!validImageTypes.includes(file[0].type)) {
            return 'Image type should be .png or .jpg';
        }
    };

    /* --------------------------------------------- 
          VALIDATE INTEGER GOAL VALUE
    --------------------------------------------- */
    const validateGoalPattern = (goal) => {
        if (!/^\d+$/.test(Number(goal))) {
            return 'Goal must be only integer numbers';
        }
    };

    /* --------------------------------------------- 
          VALIDATE STATRT DATE
    --------------------------------------------- */
    const validateStartDatePattern = (date) => {
        if (new Date().getTime() - Number(1000 * 60) > new Date(date).getTime()) {
            return 'Start date should not be in the past';
        }
    };

    /* --------------------------------------------- 
          VALIDATE STATRT AT DATE
    --------------------------------------------- */
    const validateEndDatePattern = (date) => {
        if (
            watch('start_at') &&
            new Date(date).getTime() >
                new Date(watch('start_at')).getTime() + Number(1000 * 60 * 60 * 24 * appSettings.maxDuration)
        ) {
            return `Project duration shouldn't exceed ${appSettings.maxDuration} days`;
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE PROJECT NAME
    --------------------------------------------- */
    const validateUniquetitle = (title) => {
        if (allProjects?.map((camp) => camp?.title)?.includes(title)) {
            return 'This title is already used before';
        }
    };

    return (
        <>
            {!submit && (
                <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                    <div className='row g-4'>
                        {/* TITLE */}
                        <div className='col-lg-12'>
                            <label className='form-label' htmlFor='title'>
                                Title
                            </label>
                            <input
                                type='text'
                                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                id='title'
                                placeholder='Enter project title name'
                                name='title'
                                {...register('title', {
                                    required: {
                                        value: true,
                                        message: 'Enter project title name',
                                    },
                                    validate: validateUniquetitle,
                                })}
                            />
                            {errors.title && <span className='invalid-feedback'>{errors.title?.message}</span>}
                        </div>

                        {/* DESCRIPTION */}
                        <div className='col-lg-12'>
                            <label className='form-label' htmlFor='description'>
                                Description
                            </label>
                            <textarea
                                rows='7'
                                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                id='description'
                                placeholder='Enter the project description'
                                name='description'
                                {...register('description', {
                                    required: {
                                        value: true,
                                        message: 'Enter the project description',
                                    },
                                    minLength: {
                                        value: 40,
                                        message: 'project description must be more than 40 characters',
                                    },
                                })}
                            ></textarea>
                            {errors.description && (
                                <span className='invalid-feedback'>{errors.description?.message}</span>
                            )}
                        </div>

                        {/* GOAL */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='goal'>
                                Goal - USD
                            </label>
                            <input
                                type='number'
                                step='1'
                                className={`form-control ${errors.goal ? 'is-invalid' : ''}`}
                                id='goal'
                                placeholder='Enter your project goal'
                                name='goal'
                                {...register('goal', {
                                    required: {
                                        value: true,
                                        message: 'Enter your project goal',
                                    },
                                    min: {
                                        value: 100,
                                        message: 'Goal must be at least 100 USD',
                                    },
                                    validate: validateGoalPattern,
                                })}
                            />
                            {errors.goal && <span className='invalid-feedback'>{errors.goal?.message}</span>}
                        </div>

                        {/* CATEGORY */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='category'>
                                Category
                            </label>
                            <Controller
                                name='category'
                                control={control}
                                rules={{ required: 'Please select a category' }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            options={categories}
                                            id='category'
                                            className={`border-0 shadow-sm ${errors.category ? 'is-invalid' : ''}`}
                                            classNamePrefix='select'
                                            placeholder='Select'
                                            isSearchable={true}
                                            {...field}
                                        />
                                        {errors.category && (
                                            <span className='invalid-feedback'>{errors.category?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>

                        {/* START AT */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='start_at'>
                                Start At
                            </label>
                            <Controller
                                name='start_at'
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please choose when project will start',
                                    },
                                    validate: validateStartDatePattern,
                                }}
                                render={({ field }) => (
                                    <>
                                        <DatePicker
                                            className='form-control'
                                            {...field}
                                            selected={watch('start_at') || new Date()}
                                            showTimeSelect
                                        />
                                        {errors.start_at && (
                                            <span className='invalid-feedback'>{errors.start_at?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>

                        {/* END AT */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='end_at'>
                                End At
                            </label>
                            <Controller
                                name='end_at'
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please choose when project will end',
                                    },
                                    validate: validateEndDatePattern,
                                }}
                                render={({ field }) => (
                                    <>
                                        <DatePicker
                                            className='form-control'
                                            {...field}
                                            selected={watch('end_at') || new Date()}
                                            showTimeSelect
                                        />
                                        {errors.end_at && (
                                            <span className='invalid-feedback'>{errors.end_at?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>

                        <div className='col-12'>
                            <label className='form-label' htmlFor='image'>
                                Cover Image
                            </label>
                            <input
                                type='file'
                                className={`form-control ${errors.image ? 'is-invalid' : ''}`}
                                id='image'
                                placeholder='Upload cover image'
                                name='image'
                                {...register('image', {
                                    required: {
                                        value: true,
                                        message: 'Upload cover image',
                                    },
                                    validate: validateImageFileType,
                                })}
                            />
                            {errors.image && <span className='invalid-feedback'>{errors.image?.message}</span>}
                        </div>

                        <div className='col-12'>
                            {account ? (
                                <button className='btn btn-primary' type='submit'>
                                    Start your project
                                </button>
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </form>
            )}
        </>
    );
}

export default ProjectForm;
