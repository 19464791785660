import React from 'react';
import ReactDOM from 'react-dom';
import './scss/app.scss';
import App from './App';
import Web3Provider from './providers/Web3Provider';
import AppProvider from './providers/AppProvider';
import UserProvider from './providers/UserProvider';
import ProjectProvider from './providers/ProjectProvider';

ReactDOM.render(
    <React.StrictMode>
        <Web3Provider>
            <UserProvider>
                <ProjectProvider>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </ProjectProvider>
            </UserProvider>
        </Web3Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
