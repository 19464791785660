import React, { useMemo } from 'react';

// HOOKS
import useProject from '../../hooks/useProject';
import useUser from '../../hooks/useUser';

function AppStats() {
    const { allProjects } = useProject();
    const { usersList } = useUser();

    // Pending projects
    const PendingProjects = useMemo(() => {
        return allProjects?.filter((camp) => camp?.pending);
    }, [allProjects]);

    // Successful Projects
    const successfulProjects = useMemo(() => {
        return allProjects?.filter((camp) => camp?.ended && camp?.pledged >= camp?.goal);
    }, [allProjects]);

    // Failed Projects
    const failedProjects = useMemo(() => {
        return allProjects?.filter((camp) => camp?.ended && camp?.pledged < camp?.goal);
    }, [allProjects]);

    // Active Projects
    const activeProjects = useMemo(() => {
        return allProjects?.filter((camp) => !camp?.pending && !camp?.ended);
    }, [allProjects]);

    // Total Investments
    const totalInvestments = useMemo(() => {
        if (allProjects?.length > 0) {
            return allProjects?.map((camp) => camp?.pledged)?.reduce((a, b) => Number(a) + Number(b));
        } else {
            return '0';
        }
    }, [allProjects]);

    // TOTAL PROJECTS VALUE
    const totalCampaingsValue = useMemo(() => {
        if (allProjects?.length > 0) {
            return allProjects?.map((camp) => camp?.goal)?.reduce((a, b) => Number(a) + Number(b));
        } else {
            return '0';
        }
    }, [allProjects]);

    return (
        <>
            <div className='row g-3'>
                {/* ALL PROJECTS */}
                <div className='col-lg-3 col-md-6'>
                    <div className='card mb-0'>
                        <div className='card-body text-center'>
                            <p className='h1'>{allProjects?.length}</p>
                            <p className='mb-0'>Total Projects</p>
                        </div>
                    </div>
                </div>

                {/* ALL USERS */}
                <div className='col-lg-3 col-md-6'>
                    <div className='card mb-0'>
                        <div className='card-body text-center'>
                            <p className='h1'>{usersList?.length}</p>
                            <p className='mb-0'>Total Users</p>
                        </div>
                    </div>
                </div>

                {/* ACTIVE PROJECTS */}
                <div className='col-lg-3 col-md-6'>
                    <div className='card mb-0'>
                        <div className='card-body text-center'>
                            <p className='h1'>{activeProjects?.length}</p>
                            <p className='mb-0'>Active Projects</p>
                        </div>
                    </div>
                </div>

                {/* PENDING PROJECTS */}
                <div className='col-lg-3 col-md-6'>
                    <div className='card mb-0'>
                        <div className='card-body text-center'>
                            <p className='h1'>{PendingProjects?.length}</p>
                            <p className='mb-0'>Pending Projects</p>
                        </div>
                    </div>
                </div>

                {/* SUCCESSFUL PROJECTS */}
                <div className='col-lg-3 col-md-6'>
                    <div className='card mb-0'>
                        <div className='card-body text-center'>
                            <p className='h1'>{successfulProjects?.length}</p>
                            <p className='mb-0'>Successful Projects</p>
                        </div>
                    </div>
                </div>

                {/* FAILED PROJECTS */}
                <div className='col-lg-3 col-md-6'>
                    <div className='card mb-0'>
                        <div className='card-body text-center'>
                            <p className='h1'>{failedProjects?.length}</p>
                            <p className='mb-0'>Failed Projects</p>
                        </div>
                    </div>
                </div>

                {/* TOTAL Investments */}
                {totalInvestments && (
                    <div className='col-lg-3 col-md-6'>
                        <div className='card mb-0'>
                            <div className='card-body text-center'>
                                <p className='h1'>
                                    {totalInvestments} <span className='text-sm'>USD</span>
                                </p>
                                <p className='mb-0'>Total Investments</p>
                            </div>
                        </div>
                    </div>
                )}

                {/* TOTAL PROJECTS VALUE */}
                {totalCampaingsValue && (
                    <div className='col-lg-3 col-md-6'>
                        <div className='card mb-0'>
                            <div className='card-body text-center'>
                                <p className='h1'>
                                    {totalCampaingsValue} <span className='text-sm'>USD</span>
                                </p>
                                <p className='mb-0'>Total Projects Value</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default AppStats;
