import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import "aos/dist/aos.css";
import * as bootstrap from "bootstrap";

// PAGES
import HomePage from "./pages/home";
import Layout from "./pages/Layout";
import NotFound from "./components/NotFound";
import RegisterPage from "./pages/register";
import CreateProjectPage from "./pages/project-create";
import CampaingSinglePage from "./pages/project-single";
import ProjectsPage from "./pages/projects";
import CategoryPage from "./pages/category";
import AccountPage from "./pages/account";
import AboutUsPage from "./pages/about";
import HowItWorksPage from "./pages/howitworks";
import useApp from "./hooks/useApp";
import useWeb3 from "./hooks/useWeb3";
import AdminPanel from "./pages/admin";
import ActivityPage from "./pages/activity";
import FAQsPage from "./pages/faqs";

// HOOKS
import useUser from "./hooks/useUser";

window.bootstrap = bootstrap;

function App() {
  const { isRegistered } = useUser();
  const { owner } = useApp();
  const { account } = useWeb3();

  return (
    <HashRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/signup" element={<RegisterPage />} />
          <Route path="/create-project" element={<CreateProjectPage />} />
          <Route path="/projects/:title" element={<CampaingSinglePage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route
            path="/projects/category/:category"
            element={<CategoryPage />}
          />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/how-it-works" element={<HowItWorksPage />} />

          <Route path="/activities" element={<ActivityPage />} />
          <Route path="/faqs" element={<FAQsPage />} />
          {isRegistered && <Route path="/account" element={<AccountPage />} />}

          {isRegistered && <Route path="/admin" element={<AdminPanel />} /> }

          {/* 404 */}
          <Route path="/*" element={<HomePage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
