import { readContract } from '@wagmi/core';

async function loadProjectContributors(contract, abi, id) {
    
    try {
        const userInvestments = await readContract({
            address: contract?.address,
            abi: abi,
            functionName: 'contributors',
            args: [Number(id)],
        });

        return userInvestments;
    } catch (err) {
        console.log(err);
    }
}

export default loadProjectContributors;
