import React from 'react';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';

function AboutUsPage() {
    return (
        <>
            <PageBanner
                heading='About Us'
                text='We aim to democratize access to equity investments, making it feasible for everyone, everywhere.'
            ></PageBanner>

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row gy-4 gx-5 justify-content-between'>
                                <div className='col-lg-4'>
                                    <h2>Empowering Investors Through Tokenization</h2>
                                    <p className='text-muted'>Welcome to Restifi, where the future of investment unfolds.</p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                    In a world dominated by traditional investment avenues, we observed a gap. A gap between tangible assets and the digital realm, between global investors and local opportunities. Restifi was born out of the vision to bridge this gap. We wanted to make equity investments not just accessible but also transparent and efficient.
                                    </p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                    Restifi is not just a platform; it's a revolution. With features tailored to optimize your investment experience, we invite you to be a part of this transformative journey. Expand your horizons, maximize your profits, and reshape the way you invest with Restifi.</p>
                                    <h5 className='text-primary mb-0'>D Ross</h5>
                                    <p className='text-muted'>Restifi, CEO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5 bg-darker'>
                <div className='container py-5'>
                    <header className='mb-5 text-center'>
                        <h2 className='h1'>Top Features</h2>
                        <p className='text-muted'>Top 3 Features in our app that are unrivaled!</p>
                    </header>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4 text-center'>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Maximize Profit with Leverage</h4>
                                            <p className='text-muted mb-0'>
                                            Harness the power of leverage to increase your exposure to potential profits without tying up all your capital.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Easily Sell Your Tokens</h4>
                                            <p className='text-muted mb-0'>
                                            With our integrated secondary market, sell your tokens anytime you want, bringing unparalleled liquidity to your investments.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Global Reach</h4>
                                            <p className='text-muted mb-0'>
                                            Dive into a world of opportunities beyond geographical boundaries. With Restifi, you have access to vetted projects from all corners of the globe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container text-center py-5'>
                    <div className='row'>
                        <div className='col-lg-6 mx-auto'>
                            <p className='h3 fw-light'>
                                "The world of investment is changing, and with Restifi, you're always ahead of the curve. "
                            </p>
                            <h5 className='text-primary'>D Ross</h5>
                            <p className='text-muted'>Restifi, CEO</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsPage;
