import React from 'react';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import ProjectForm from './ProjectForm';

function CreateProjectPage() {
    return (
        <>
            <PageBanner
                heading='Apply Project'
                text='some info about your offer'
            ></PageBanner>
            <section className='pb-5 page-first-section'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='card p-md-4'>
                                <div className='card-body'>
                                    <header className='text-center mb-4'>
                                        <h4 className='mb-0'>Fill the Form Below</h4>
                                        <p className='text-muted'>To create a new project</p>
                                    </header>

                                    <ProjectForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CreateProjectPage;
