import React from "react";
import useUser from "../../hooks/useUser";
import { Link } from "react-router-dom";
// COMPOENENTS
import PageBanner from "../../components/general/PageBanner";

function HowItWorksPage() {
    const { isRegistered } = useUser();
  return (
    <>
      <div style={{ display: "none" }}>
        <PageBanner
          style={{ display: "none" }}
          heading="How it works"
          text="We aim to democratize access to equity investments, making it feasible for everyone, everywhere."
        ></PageBanner>
      </div>

      <section className="py-5 bg-darker">
        <div className="container py-5">
          <header className="mb-5 text-center">
            <h2 className="h1">How It Works</h2>
            <p className="text-muted">
              We aim to democratize access to equity investments, making it
              feasible for everyone, everywhere.
            </p>
          </header>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="row g-4 text-center">
                <div className="col-lg-6">
                  <div className="card mb-0 p-lg-4 shadow-0">
                    <div className="card-body">
                      <h4>Sign Up</h4>
                      <p className="text-muted mb-0">
                        Create your account effortlessly and start exploring the
                        world of tokenized assets.
                      </p>

                      <Link
                        style={{ marginTop: "10px" }}
                        to="/signup"
                        className="btn btn-outline-primary"
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mb-0 p-lg-4 shadow-0">
                    <div className="card-body">
                      <h4>Explore Assets</h4>
                      <p className="text-muted mb-0">
                        Browse through a diverse range of tokenized assets and
                        find your next investment opportunity.
                      </p>
                      <Link
                        style={{ marginTop: "10px" }}
                        to="/projects"
                        className="btn btn-outline-info "
                      >
                        Browse projects
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mb-0 p-lg-4 shadow-0">
                    <div className="card-body">
                      <h4>Invest Securely</h4>
                      <p className="text-muted mb-0">
                        Invest with confidence on our secure, user-friendly
                        platform.
                      </p>
                      <Link
                        style={{ marginTop: "10px" }}
                        to="/signup"
                        className="btn btn-outline-danger"
                      >
                        Start Investing
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mb-0 p-lg-4 shadow-0">
                    <div className="card-body">
                      <h4>Track & Manage</h4>
                      <p className="text-muted mb-0">
                        Monitor your investments and manage your portfolio with
                        real-time updates and analytics.
                      </p>
                      <Link
                        style={{ marginTop: "10px" }}
                        to={!isRegistered ?  "/signup" : "/account" }
                        className="btn btn-outline-warning"
                      >
                        Manage Portfolio
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-darker">
        <div className="container  py-5">
          <div className="row g-5 align-items-center pb-5">
            <div className="col-lg-10 mx-auto ">
              <h2 className="h1">Meticulous Project Vetting</h2>
              <p className="text-muted mb-4">
                At Restifi, we meticulously analyze a vast array of investment
                opportunities, ensuring only the finest selections make their
                way to you. Our dedicated investment team engages in a rigorous
                vetting process, filtering through billions in potential
                investments to identify those with the most promise.
              </p>

              <div className="row">
                <div className="col-xl-8">
                  <ul className="list-unstyled">
                    <li className="d-flex align-items">
                      <div className="ms-3">
                        <h5>Exclusive Opportunity Curation:</h5>
                        <p className="text-sm text-muted">
                          We handpick unique investment opportunities, ensuring
                          they align with our stringent criteria for excellence.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items">
                      <div className="ms-3">
                        <h5>Comprehensive Due Diligence:</h5>
                        <p className="text-sm text-muted">
                          Each potential investment undergoes a thorough review,
                          scrutinizing every facet to uphold our high standards.
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items">
                      <div className="ms-3">
                        <h5>Consistent Monitoring & Reporting: </h5>
                        <p className="text-sm text-muted">
                          Our commitment extends beyond selection. We
                          continuously oversee and report on each investment,
                          ensuring ongoing quality and performance.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="row gy-4 gx-5 justify-content-between">
                <div className="col-lg-4">
                  <h2>Optimizing Your Investment Portfolio</h2>
                </div>
                <div className="col-lg-8">
                  <p>
                    <h6 className="text-success">
                      Wondering about the ideal allocation to private markets?
                    </h6>{" "}
                    Renowned investment managers, including BlackRock, suggest
                    enhancing traditional stock and bond portfolios with up to
                    20% in private market alternatives. Diversifying with these
                    alternatives not only broadens your investment landscape but
                    also holds the potential for augmented returns.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row pb-5">
          <div className="col-lg-5 mx-auto">
            <p className="lead mb-0">Your wealth starts from here</p>
            <h2 className="h1 text-white mb-0">Ready to Build Your Future?</h2>
          </div>
          <div className="col-lg-5 mx-auto text-lg-end" >
            <Link className="btn btn-light bg-white text-dark" to="/projects">
              Get Started Now
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowItWorksPage;
