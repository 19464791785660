import React, { useReducer } from "react";
import { readContract } from "@wagmi/core";

import ProjectContext from "./project-context";
import Web3 from "web3";

const defaultProjectState = {
  projectContract: null,
  projectContractAbi: null,
};

const appReducer = (state, action) => {
  if (action.type === "CONTRACT") {
    return {
      ...state,
      projectContract: action.projectContract,
    };
  }
  if (action.type === "GET_ABI") {
    return {
      ...state,
      projectContractAbi: action.projectContractAbi,
    };
  }
  if (action.type === "GET_PROJECTS") {
    console.error(action.allProjects);
    return {
      ...state,
      pendingProjects: action.allProjects
        ?.map((camp) => {
          return {
            id: Number(camp._id),
            title: camp.description.title,
            description: camp.description.desc,
            creator: camp.creator,
            goal: Web3.utils.fromWei(camp.goal.toString(), "gwei"),
            pledged: Web3.utils.fromWei(camp.pledged.toString(), "gwei"),
            startAt: Number(camp.startAt) * 1000,
            endAt: Number(camp.endAt) * 1000,
            claimed: camp.claimed,
            status:
              camp.status === 0
                ? "pending"
                : camp.status === 1
                ? "approved"
                : "canceled",
            category: camp.description.category,
            cover: camp.description.image,
            pending:
              new Date().getTime() <
              new Date(Number(camp.startAt) * 1000).getTime(),
            ended:
              new Date().getTime() >
              new Date(Number(camp.endAt) * 1000).getTime(),
            votes: camp.votes.toString(),
            financialInfo: {
              arr: camp.financialInfo.arr.toString(),
              term: camp.financialInfo.term.toString(),
              payment_schedule: camp.financialInfo.payment_schedule.toString(),
            },
            descriptionInfo: {
              title: camp.description.title.toString(),
              desc: camp.description.desc.toString(),
              tags: camp.description.tags.toString(),
              country: camp.description.country.toString(),
              image: camp.description.image.toString(),
            },
          };
        })
        ?.filter((camp) => camp?.status === "pending")
        ?.sort((a, b) => b?.id - a?.id),
      allProjects: action.allProjects
        ?.map((camp) => {
          return {
            id: Number(camp._id),
            title: camp.description.title,
            description: camp.description.desc,
            creator: camp.creator,
            goal: Web3.utils.fromWei(camp.goal.toString(), "gwei"),
            pledged: Web3.utils.fromWei(camp.pledged.toString(), "gwei"),
            startAt: Number(camp.startAt) * 1000,
            endAt: Number(camp.endAt) * 1000,
            claimed: camp.claimed,
            status:
              camp.status === 0
                ? "disaproved"
                : camp.status === 1
                ? "approved"
                : "canceled",
            category: camp.description.category,
            cover: camp.description.image,
            pending:
              new Date().getTime() <
              new Date(Number(camp.startAt) * 1000).getTime(),
            ended:
              new Date().getTime() >
              new Date(Number(camp.endAt) * 1000).getTime(),
            votes: camp.votes.toString(),
            financialInfo: {
              arr: camp.financialInfo.arr.toString() / 100 ,
              term: camp.financialInfo.term.toString(),
              payment_schedule: camp.financialInfo.payment_schedule.toString(),
            },
            descriptionInfo: {
              title: camp.description.title.toString(),
              desc: camp.description.desc.toString(),
              tags: camp.description.tags.toString(),
              country: camp.description.country.toString(),
              image: camp.description.image.toString(),
            },
          };
        })

        ?.filter((camp) => camp?.status === "approved")
        ?.sort((a, b) => b?.id - a?.id),
    };
  }
  
  return defaultProjectState;
};

const ProjectProvider = (props) => {
  const [projectState, dispatchProjectAction] = useReducer(
    appReducer,
    defaultProjectState
  );

  const getProjectContractAbiHandler = (projectContractAbi) => {
    dispatchProjectAction({
      type: "GET_ABI",
      projectContractAbi: projectContractAbi,
    });
  };

  const loadProjectContractHandler = (projectContract) => {
    dispatchProjectAction({
      type: "CONTRACT",
      projectContract: projectContract,
    });
    return projectContract;
  };

  const loadAllProjectsHandler = async (projectContract, abi) => {
    const allProjects = await readContract({
      address: projectContract.address,
      abi: abi,
      functionName: "getProjects",
    });

    dispatchProjectAction({ type: "GET_PROJECTS", allProjects: allProjects });
    return allProjects;
  };

  const projectContext = {
    projectContract: projectState.projectContract,
    projectContractAbi: projectState.projectContractAbi,
    allProjects: projectState.allProjects,
    pendingProjects: projectState.pendingProjects,
    loadProjectContract: loadProjectContractHandler,
    getProjectContractAbi: getProjectContractAbiHandler,
    loadAllProjects: loadAllProjectsHandler,
  };

  return (
    <ProjectContext.Provider value={projectContext}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
