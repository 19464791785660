import React from 'react';

const ProjectContext = React.createContext({
    projectContract: null,
    projectContractAbi: null,
    allProjects: [],
    pendingProjects: [],
    loadProjectContract: () => {},
    getProjectContractAbi: () => {},
    loadAllProjects: () => {},
});

export default ProjectContext;
