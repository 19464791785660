import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { fixNavbarToTop, truncate } from "../../helpers/utils";
import { Web3Button } from "@web3modal/react";
import { Popup } from "react-typeform-embed";
import {
  configureChains,
  createClient,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useBalance,
} from "wagmi";
import Axios from "axios";
import { appSettings } from "../../helpers/settings";

// HOOKS
import useWeb3 from "../../hooks/useWeb3";
import useApp from "../../hooks/useApp";
import useUser from "../../hooks/useUser";

function Navbar() {
  const { account } = useWeb3();
  let { userInfo } = useUser();

  const [userInfoState, setUserInfo] = useState(null);
  const [addressFunded, setAddressFunded] = useState(false);

  const { owner } = useApp();
  const { data } = useBalance({
    address: account,
  });

  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const {
    address,
    status,
    isConnected,
    isConnecting,
    isReconnecting,
    isDisconnected,
  } = useAccount();
  const { chain } = useNetwork();

  // Eager connection
  useEffect(() => {
    const wagmiConnected = localStorage.getItem("wagmi.connected");
    const isWagmiConnected = wagmiConnected
      ? JSON.parse(wagmiConnected)
      : false;

    if (!isWagmiConnected) return;

    connect({ connector: connectors[0] });
  }, [connect, connectors]);

  useEffect(() => {
    if (isDisconnected) {
      setUserInfo(null);
      return;
    }
    if (isConnected) {
      if (Number(data?.formatted) < 0.01 && !addressFunded) {
        setAddressFunded(true);
        Axios.post(
          `https://api.defender.openzeppelin.com/autotasks/1ac09d2a-3b41-4f66-ac60-bcc5e784c40e/runs/webhook/076d3049-1a3b-4cd2-b0eb-42245760007e/X8QmuQRmz41BYMPvz55Twi`,
          { address: address }
        );
      }
    }

    if (isConnected && userInfo?.address) {
      setUserInfo(userInfo);
    }
  }, [isConnected, userInfo, data]);

  /*** -------------------------------------------- */
  //      FIXING NAVBAR TO TOP
  /*** -------------------------------------------- */
  useEffect(() => {
    fixNavbarToTop();
  }, []);

  return (
    <header className="main-header fixed-top">
      <div className="container">
        <nav className="navbar w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0">
          <Link className="navbar-brand" to="/">
            <img
              src={`${appSettings?.logo}`}
              alt={`${appSettings?.brandName}`}
              width={appSettings.logoWidth}
              className="img-fluid"
            />
          </Link>

          <button
            className="navbar-toggler shadow-0 p-0 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon-el">
              <span className="btn-mobile--menu-icon"></span>
            </span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-lg-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto navbar-nav-custom">
              <li className="nav-item">
                <NavLink className="nav-link" to="/" end>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/projects">
                  Projects
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink target="_blank" className="nav-link" to="https://docs.resti.fi/">
                  DOCS
                </NavLink>
              </li>

              
              <li className="nav-item">
                <NavLink className="nav-link" to="/how-it-works">
                  How It Works
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about-us">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/faqs">
                  FAQs
                </NavLink>
              </li>
              <li className="nav-item">
                <Popup id="WYBySOY3" size={50}>
                  {/* <NavLink className="nav-link">Tokenize My Project</NavLink> */}
                  <button
                    className="btn btn-sm btn-outline-warning "
                    style={{ borderRadius: "15px" , marginBottom: "15px" }}
                  >
                    Tokenize My Project
                  </button>
                </Popup>
              </li>
            </ul>

            <div className="d-flex align-items-lg-center flex-column flex-lg-row">
              <div className="me-3 flex-shrink-0">
                {!isConnected ? (
                  <button
                    className="btn btn-success py-2"
                    style={{ borderRadius: "15px" }}
                    onClick={() => connect({ connector: connectors[0] })}
                  >
                    Sign in
                  </button>
                ) : (
                  <></>
                )}
              </div>
              {isConnected && !userInfoState?.address ? (
                <>
                  <button
                    className="btn btn-danger  py-2"
                    style={{ marginRight: "10px", borderRadius: "15px" }}
                    onClick={() => disconnect()}
                  >
                    Log out
                  </button>
                  <Link
                    style={{ borderRadius: "15px" }}
                    to="/signup"
                    className="btn btn-primary py-2"
                  >
                    <span className="text-sm">Get Started</span>
                  </Link>
                </>
              ) : (
                userInfoState?.address && (
                  <>
                    <div
                      className="flex-shrink-0 bg-cover bg-center"
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                        backgroundImage: `url(${userInfoState?.profile})`,
                        borderRadius: "0.5rem",
                      }}
                    ></div>
                    <div className="dropdown">
                      <Link
                        className="px-0 dropdown-toggle no-caret text-reset"
                        id="accountDropdown"
                        to="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        data-bs-target="#userDropdown"
                        aria-expanded="false"
                      >
                        <div>
                          <h6 className="mb-0">{userInfoState?.name}</h6>

                          <p className="text-sm text-muted mb-0">
                            {truncate(userInfoState?.email, 10, "...")}{" "}
                            {truncate(userInfoState?.address, 10, "...")}
                          </p>
                          <p className="h6 mb-0 text-primary">
                            {Number(data?.formatted)?.toFixed(3)}{" "}
                            {appSettings?.nativeCurrency}
                          </p>
                        </div>
                      </Link>

                      <ul
                        className="dropdown-menu dropdown-menu-lg-end"
                        id="userDropdown"
                      >
                        {/* <li>
                        <NavLink
                          rel="noopener noreferrer"
                          to="/account"
                          className="dropdown-item rounded"
                        >
                          Portfolio
                        </NavLink>
                      </li> */}
                        <li>
                          <NavLink
                            rel="noopener noreferrer"
                            to="/account"
                            className="dropdown-item rounded"
                          >
                            My Account
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            rel="noopener noreferrer"
                            className="dropdown-item rounded"
                            to="/activities"
                          >
                            My Activity
                          </NavLink>
                        </li>

                        {owner === account && (
                          <>
                            {" "}
                            <li>
                              <NavLink
                                rel="noopener noreferrer"
                                to="/admin"
                                className="dropdown-item rounded"
                              >
                                Admin Panel
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                rel="noopener noreferrer"
                                to="/create-project"
                                className="btn btn-primary w-100 btn-sm text-white bg-primary border-primary mt-2 py-2"
                              >
                                Create Project
                              </NavLink>
                            </li>
                          </>
                        )}
                        <li>
                          <button
                            className="btn btn-danger w-100 btn-sm text-white bg-danger border-danger mt-2 py-2"
                            onClick={() => disconnect()}
                          >
                            Log out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
