import React from "react";
import { Link } from "react-router-dom";

// HOOKS
import useUser from "../../hooks/useUser";

function HeroBanner() {
  const { isRegistered } = useUser();

  return (
    <>
      <section className="hero-banner">
        <div className="hero-banner-bg"></div>
        <div className="container z-index-20 hero-banner-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <p className="h5 text-primary">Buy, sell, earn</p>
              <h1 className="text-xxl mb-3">Tokenized equity marketplace</h1>
              <p className="text-muted mb-3">
                Get up to 45% annual returns by purchasing digital shares in
                companies.*
              </p>
              <ul className="list-inline">
                <li className="list-inline-item me-3">
                  <Link
                    to={`${isRegistered ? "/projects" : "/projects"}`}
                    className="btn btn-primary"
                  >
                    Browse projects
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="btn btn-link text-white" to="/about-us">
                    About Us
                  </Link>
                </li>
              </ul>
              <div style={{marginTop: '10px', fontSize: '11px', }} className="text-muted">
                *Disclaimer: This offer involves the YieldBooster technology for
                leveraged token buyouts. Returns up to 45% annually are
                potential, not guaranteed, and come with risks, including the
                loss of your investment. Past performance is not indicative of
                future results. Please consider these risks carefully before
                proceeding.
              </div>
            </div>

            <div className="col-lg-5 ms-auto text-center">
              <div className="oval-holder d-inline-block">
                <img src="/about-1-1.png" alt="" className="oval img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroBanner;
