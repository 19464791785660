import React, { useMemo } from 'react';
import useApp from '../../hooks/useApp';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';

// HOOKS
import useProject from '../../hooks/useProject';
import useWeb3 from '../../hooks/useWeb3';

// COMPONENTS
import ConnectWalletHander from './ConnectWalletHandler';

function VoteFundsHandler({ id, customClass, goal, endAt, pledged, creator, claimed }) {
    const { account } = useWeb3();
    const { contract, abi, setTransactionLoading, setVoteModalStatus, setVoteModalSrc } = useApp();
    const { projectContract, projectContractAbi, loadAllProjects } = useProject();


    /* --------------------------------------------- 
          CMPAIGN GOAL STATUS COMPONENT
    --------------------------------------------- */
    const projectCTA = useMemo(() => {
        return (
            <>
                {account ? (
                        <button
                            className='btn btn-primary w-100 mt-3'
                            onClick={() => {
                               
                                setVoteModalStatus(true);
                                setVoteModalSrc({ id });
                            }}
                        >
                            Vote
                        </button>
                    ) : (
                        <ConnectWalletHander />
                    )}
            </>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endAt, goal, new Date().getTime(), claimed, pledged, account, creator]);

    return projectCTA;
}

export default VoteFundsHandler;
