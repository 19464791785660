import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appSettings } from '../helpers/settings';
import { ToastContainer, Flip } from 'react-toastify';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig,  useAccount, useConnect, useDisconnect, useNetwork, createConfig } from 'wagmi';
import { getAccount, watchAccount, getContract } from '@wagmi/core';
import AOS from 'aos';
import { alchemyProvider } from 'wagmi/providers/alchemy';

import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public';
// 3RD-PARTY STYLES
import 'react-toastify/dist/ReactToastify.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-tippy/dist/tippy.css';
import 'react-range-slider-input/dist/style.css';

// HOOKS
import useWeb3 from '../hooks/useWeb3';
import useApp from '../hooks/useApp';
import useUser from '../hooks/useUser';
import useProject from '../hooks/useProject';

// CONTRACT ABIs
import UserContractAbi from '../contracts/UserContract.json';
import CrowdFundAbi from '../contracts/Crowdfund.json';
import ProjectAbi from '../contracts/ProjectContract.json';

// COMPONENTS
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import ScrollToTop from '../components/general/ScrollToTop';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import DonatePopup from '../components/general/DonatePopup';
import VotePopup from '../components/general/VotePopup';
import ViewOnlyAlert from '../components/general/ViewOnlyAlert';

import Web3AuthConnectorInstance from "../connection/Web3AuthConnectorInstance";

// CHANGE [bscTestnet] with the chain name you'll deploy on, visit https://wagmi.sh/core/chains#supported-chains to select it from the list
import { celoAlfajores, polygon } from 'wagmi/chains';

const projectId = appSettings.wcProjectId;

// CONFIGURE WAGMI CLIENT
// Configure chains & providers with the Public provider.



    
const { chains, publicClient, webSocketPublicClient } = configureChains(
    // @ts-ignore
    [polygon],
    [
      
        infuraProvider({ apiKey: '1f2d82d98b2b4c5492e4d6f7fee26836' }),
        publicProvider()
    ],
)


// Set up client
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    Web3AuthConnectorInstance(chains),
  ],
  publicClient,
  webSocketPublicClient,
});




//const ethereumClient = new EthereumClient(wagmiConfig, chains);

function Layout() {


    const { account, loadAccount } = useWeb3();
    const { projectContract, projectContractAbi, loadAllProjects, loadProjectContract, getProjectContractAbi } =
        useProject();
    const {
        transactionLoading,
        voteModalStatus,
        donateModalStatus,
        loadAppOwner,
        getContractAbi,
        loadContract,
        loadPaymentTokens,
        abi,
        contract,
    } = useApp();


    const {
        userContract,
        userContractAbi,
        loadUsersList,
        loadUserContract,
        getUserContractAbi,
        loadUserInfo,
        loadActivities,
    } = useUser();

  

    

    /* --------------------------------------------- 
          LOAD APP OWNER & PAYMENT TOKENS
    --------------------------------------------- */



    useEffect(() => {
            if (contract && account) {
                loadAppOwner(contract, abi);
                loadPaymentTokens(contract, abi, account);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, account]);

    /* ---------------------------------------------------- 
          LOAD USER INFORMATION & USERS LIST & ACTIVITIES
    ---------------------------------------------------- */
    useEffect(() => {
        if (userContract && account) {
            loadUserInfo(userContract, account);
        }

        if (userContract) {
            loadUsersList(userContract, userContractAbi);
            loadActivities(userContract, userContractAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContract, account]);

    /* --------------------------------------------- 
          LOAD ALL PROJECTS
    --------------------------------------------- */
    useEffect(() => {
        if (projectContract) {
            loadAllProjects(projectContract, projectContractAbi);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectContract]);

    /* --------------------------------------------- 
          AOS ANIMATION
    --------------------------------------------- */
    useEffect(() => {
        AOS.init({
            duration: 700,
            once: true,
        });
    }, []);

    /* --------------------------------------------- 
          GET INITIAL BLOCKCHAIN DATA
    --------------------------------------------- */
    useEffect(() => {
        const calclateInitialSettings = async () => {
            const acc = getAccount();
            loadAccount(acc?.address);
            getUserContractAbi(UserContractAbi.abi);
            getContractAbi(CrowdFundAbi.abi);
            getProjectContractAbi(ProjectAbi.abi);

            const userDeployedNetwork = UserContractAbi.networks[appSettings.networkId];
            const cfDeployedNetwork = CrowdFundAbi.networks[appSettings.networkId];
            const cmpDeployedNetwork = ProjectAbi.networks[appSettings.networkId];

            const userContract =
                userDeployedNetwork &&
                getContract({
                    address: userDeployedNetwork.address,
                    abi: UserContractAbi.abi,
                });

            const cfContract =
                cfDeployedNetwork &&
                getContract({
                    address: cfDeployedNetwork.address,
                    abi: CrowdFundAbi.abi,
                });

            const camapignCtr =
                cmpDeployedNetwork &&
                getContract({
                    address: cmpDeployedNetwork.address,
                    abi: ProjectAbi.abi,
                });

            loadUserContract(userContract);
            loadContract(cfContract);
            loadProjectContract(camapignCtr);

            // eslint-disable-next-line no-unused-vars
            const unwatch = watchAccount((account) => {
                loadAccount(account?.address);
            });
        };
        calclateInitialSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <div className='app pb-0'>
                    {/* {chain?.id !== appSettings.networkId && <ViewOnlyAlert />} */}
                    <Header />
                    <ScrollToTop />
                    <Outlet />
                    <Footer />
                </div>
                {donateModalStatus && <DonatePopup />}
                {voteModalStatus && <VotePopup />}
                <ToastContainer position='top-center' autoClose={1500} transition={Flip} />
                {transactionLoading && <MetaMaskLoader />}
            </WagmiConfig>
            
        </>
    );
}

export default Layout;
