import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { appSettings } from "../../helpers/settings";
import { toast } from "react-toastify";
import { useContractWrite } from "wagmi";
import { readContract } from "@wagmi/core";
import { toBlockExplorer } from "../../helpers/utils";

// HOOKS
import useProject from "../../hooks/useProject";
import useWeb3 from "../../hooks/useWeb3";
import useUser from "../../hooks/useUser";
import useApp from "../../hooks/useApp";

// COMPONENTS
import ProjectContributors from "./AllContributors";
import NotFound from "../../components/NotFound";
import ClaimFundsHandler from "../../components/general/ClaimFunds";

import VoteFundsHandler from "../../components/general/VoteFunds";
import UserProjectContributions from "./UserContribution";

function CampaingSinglePage() {
  const { account } = useWeb3();
  const { allProjects, projectContract, projectContractAbi, loadAllProjects } =
    useProject();
  const { paymentTokens, setTransactionLoading } = useApp();
  const { title } = useParams();
  const { usersList } = useUser();
  const today = new Date().getTime();
  const navigate = useNavigate();

  const [voted, setVoted] = useState(0);

  // TARGET PROJECT
  const currentProject = useMemo(() => {
    return allProjects?.filter((camp) => camp?.title === title)[0];
  }, [title, allProjects]);

  // GET DONATION PERCENTAGE
  const raisePercentage = useMemo(() => {
    return (currentProject?.pledged / currentProject?.goal) * 100;
  }, [currentProject]);

  const votePercentage = useMemo(() => {
    return (Number(voted) / currentProject?.goal) * 100;
  }, [currentProject, voted]);

  // CHECK IF THIS PROJECT HAS NOT LAUNCHED YET
  const isPending = useMemo(() => {
    return currentProject?.startAt > today;
  }, [currentProject, today]);

  // GET THE PROJECT OWNER
  const projectOwner = useMemo(() => {
    return usersList?.filter(
      (user) => user?.address === currentProject?.creator
    )[0];
  }, [usersList, currentProject]);

  // CHANGE THE PAGE NAME TO THE PROJECT TITLE
  useEffect(() => {
    document.title = `${appSettings.brandName} | ${
      currentProject?.title || "Not Found"
    }`;
  }, [currentProject]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentProject?.id) {
        const fetchData = async () => {
          const votes = await readContract({
            address: projectContract?.address,
            abi: projectContractAbi,
            functionName: "votes",
            args: [currentProject.id],
          });
          console.log(votes.toString());
          setVoted(votes.toString());
        };

        fetchData();
      }
    }, 5000);
    return () => clearInterval(intervalId); //This is important
  }, [currentProject]);

  /* --------------------------------------------- 
              CANCEL PROJECT HANDLER
     --------------------------------------------- */
  const { write: web3CancelProject } = useContractWrite({
    address: projectContract?.address,
    abi: projectContractAbi,
    functionName: "cancel",
    onSuccess() {
      setTimeout(() => {
        setTransactionLoading(false);
        loadAllProjects(projectContract, projectContractAbi);
        navigate("/");
        toast.success("Project has been canceled");
      }, 5000);
    },
    onMutate() {
      setTransactionLoading(true);
    },
    onError(error) {
      setTransactionLoading(false);
      toast.error("Oops! Something went error");
    },
  });

  /* --------------------------------------------- 
              TRIGGER CANCELATION
        --------------------------------------------- */
  function cancelProjectHandler() {
    web3CancelProject({
      args: [Number(currentProject?.id)],
    });
  }

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="countdown lead">
      <div className="countdown-item my-0">
        <strong className="fw-bold">{zeroPad(days)}</strong>
        <p className="mb-0 text-sm text-muted">Days</p>
      </div>
      <div className="countdown-item my-0">
        <strong className="fw-bold">{zeroPad(hours)}</strong>
        <p className="mb-0 text-sm text-muted">Hours</p>
      </div>
      <div className="countdown-item my-0">
        <strong className="fw-bold">{zeroPad(minutes)}</strong>
        <p className="mb-0 text-sm text-muted">Minutes</p>
      </div>
      <div className="countdown-item my-0">
        <strong className="fw-bold">{zeroPad(seconds)}</strong>
        <p className="mb-0 text-sm text-muted">Seconds</p>
      </div>
    </div>
  );

  return (
    <>
      {currentProject ? (
        <section className="hero-banner">
          <div className="hero-banner-bg"></div>
          <div className="container z-index-20 hero-banner-container">
            <div className="row justify-content-center g-5">
              <div
                className="col-lg-3 text-center"
                
              >
                <img
                  src={currentProject?.cover}
                  alt={`${currentProject?.title}`}
                  className="img-fluid project-single-img w-100"
                />

                <UserProjectContributions
                  id={currentProject?.id}
                  endAt={currentProject?.endAt}
                  goal={currentProject?.goal}
                  pledged={currentProject?.pledged}
                  ended={currentProject?.ended}
                />
              </div>
              <div className="col-lg-7">
                <p>
                  <Link
                    to={`/projects/category/${currentProject?.category}`}
                    className="text-reset"
                  >
                    <span className="badge bg-primary fw-normal lead">
                      {currentProject?.category}
                    </span>
                  </Link>
                </p>
                <h1 className="text-xl mb-3">{currentProject?.title}</h1>
                {projectOwner && (
                  <a
                    className="d-inline-block text-reset"
                    href={`${toBlockExplorer(
                      "address",
                      projectOwner?.address
                    )}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="glass-bg p-3 mb-3">
                      <p className="text-xs">Created By</p>
                      <div className="d-flex align-items-center">
                        <div
                          className="flex-shrink-0 bg-cover bg-center"
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundImage: `url(${projectOwner?.profile})`,
                            borderRadius: "0.5rem",
                          }}
                        ></div>
                        <div className="ms-3">
                          <h6 className="mb-0" style={{ fontSize: "0.9rem" }}>
                            {projectOwner?.name}
                          </h6>
                          {/* <p className='text-muted small mb-0'>{projectOwner?.email}</p> */}
                          <p className="text-muted small mb-0">
                            verified by Restifi
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                )}

                <div className="row justify-content-center gy-4">
                  <div
                    style={{ borderBottom: "1px solid #fff" }}
                    className="col-lg-6 dash my-4"
                  >
                    <div className="d-flex align-items-center justify-content-between text-sm ">
                      <span className="h6 mb-1">Boosted annual return</span>
                      <span className="text-primary mb-1">
                        {(currentProject?.financialInfo?.arr * 1.45)?.toFixed(
                          2
                        )}
                        %
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ borderBottom: "1px solid #fff" }}
                    className="col-lg-6 dash my-4"
                  >
                    <div className="d-flex align-items-center justify-content-between text-sm ">
                      <span className="h6 mb-1">Projected annual return</span>
                      <span className=" mb-1">
                        {currentProject?.financialInfo?.arr?.toFixed(2)}%
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ borderBottom: "1px solid #fff" }}
                    className="col-lg-6 dash my-4"
                  >
                    <div className="d-flex align-items-center justify-content-between text-sm ">
                      <span className="h6 mb-1">Term</span>
                      <span className=" mb-1">
                      {currentProject?.financialInfo?.term == "0"
                      ? "no term"
                      : currentProject?.financialInfo?.term + " mo"}
                        
                      </span>
                    </div>
                  </div>   

                  <div
                    style={{ borderBottom: "1px solid #fff" }}
                    className="col-lg-6 dash my-4"
                  >
                    <div className="d-flex align-items-center justify-content-between text-sm ">
                      <span className="h6 mb-1">Payment Schedule</span>
                      <span className=" mb-1">
                      every {currentProject?.financialInfo?.payment_schedule} month
                        
                      </span>
                    </div>
                  </div>     


                  <div
                    style={{ borderBottom: "1px solid #fff" }}
                    className="col-lg-6 dash my-4"
                  >
                    <div className="d-flex align-items-center justify-content-between text-sm ">
                      <span className="h6 mb-1">Offering structure</span>
                      <span className=" mb-1">SPV managed by DAO</span>
                    </div>
                  </div>
                  <div
                    style={{ borderBottom: "1px solid #fff" }}
                    className="col-lg-6 dash my-4"
                  >
                    <div className="d-flex align-items-center justify-content-between text-sm">
                      <span className="h6 mb-1">Asset location</span>
                      <span className="mb-1">
                        {currentProject?.descriptionInfo?.country}
                      </span>
                    </div>
                  </div>





                </div>

                <div style={{display: ''}} className="goal my-4">
                  {!isPending ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between text-sm">
                        <span className="text-muted">
                          Raised: {currentProject?.pledged}
                        </span>
                        <span className="text-muted">
                          {raisePercentage?.toFixed(2)}%
                        </span>
                      </div>

                      <div className="progress my-2" style={{ height: "4px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${raisePercentage}%` }}
                          aria-valuenow={raisePercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-between text-sm">
                        <span className="text-muted">Voted: {voted} USD</span>
                        <span className="text-muted">
                          {votePercentage?.toFixed(2)}%
                        </span>
                      </div>

                      <div className="progress my-2" style={{ height: "4px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${votePercentage}%` }}
                          aria-valuenow={votePercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )}

                  <p className="h6 mb-3">
                    Goal{" "}
                    <span className="text-primary">{currentProject?.goal}</span>{" "}
                    <span className="text-xs">USD</span>
                  </p>

                  {paymentTokens?.length > 0 && (
                    <p className="small">
                      Accepts{" "}
                      {paymentTokens?.map((token, index) => {
                        return (
                          <a
                            href={`${toBlockExplorer("token", token?.address)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-reset d-inline-block"
                            key={index}
                          >
                            <span
                              className="badge me-1 mb-1 bg-secondary"
                              key={index}
                            >
                              {token?.symbol}
                            </span>
                          </a>
                        );
                      })}
                    </p>
                  )}

                  {!isPending ? (
                    <div className="mt-3">
                      <ClaimFundsHandler
                        customClass="btn btn-primary w-100"
                        endAt={currentProject?.endAt}
                        goal={currentProject?.goal}
                        pledged={currentProject?.pledged}
                        id={currentProject?.id}
                        creator={currentProject?.creator}
                        claimed={currentProject?.claimed}
                      />
                    </div>
                  ) : (
                    new Date().getTime() < currentProject?.startAt && (
                      <>
                        <div className="glass-bg p-4">
                          <h6 className="text-center mb-3">
                            Vote for the project
                          </h6>
                          <Countdown
                            date={currentProject?.startAt}
                            renderer={renderer}
                            onComplete={() => {
                              loadAllProjects(
                                projectContract,
                                projectContractAbi
                              );
                            }}
                          />

                          <VoteFundsHandler
                            customClass="btn btn-primary w-100"
                            endAt={currentProject?.endAt}
                            goal={currentProject?.goal}
                            pledged={currentProject?.pledged}
                            id={currentProject?.id}
                            creator={currentProject?.creator}
                            claimed={currentProject?.claimed}
                          />

                          {account === currentProject?.creator && (
                            <button
                              className="glassy-btn w-100 mt-3"
                              type="button"
                              onClick={cancelProjectHandler}
                            >
                              Cancel this project
                            </button>
                          )}
                        </div>
                      </>
                    )
                  )}

                  {new Date().getTime() > currentProject?.startAt && (
                    <div className="glass-bg p-4">
                      {new Date().getTime() < currentProject?.endAt ? (
                        <>
                          <h6 className="text-center mb-3">
                            This Project is valid until
                          </h6>
                          <Countdown
                            date={currentProject?.endAt}
                            renderer={renderer}
                            onComplete={() => {
                              loadAllProjects(
                                projectContract,
                                projectContractAbi
                              );
                            }}
                          />
                        </>
                      ) : (
                        <p className="mb-0">This project has been ended</p>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="descr "
                  style={{  width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: currentProject?.description,
                  }}
                />

                {/* PROGRESS */}
                <div style={{display: ''}} className="goal my-4">
                  {!isPending ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between text-sm">
                        <span className="text-muted">
                          Raised: {currentProject?.pledged}
                        </span>
                        <span className="text-muted">
                          {raisePercentage?.toFixed(2)}%
                        </span>
                      </div>

                      <div className="progress my-2" style={{ height: "4px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${raisePercentage}%` }}
                          aria-valuenow={raisePercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-between text-sm">
                        <span className="text-muted">Voted: {voted} USD</span>
                        <span className="text-muted">
                          {votePercentage?.toFixed(2)}%
                        </span>
                      </div>

                      <div className="progress my-2" style={{ height: "4px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${votePercentage}%` }}
                          aria-valuenow={votePercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )}

                  <p className="h6 mb-3">
                    Goal{" "}
                    <span className="text-primary">{currentProject?.goal}</span>{" "}
                    <span className="text-xs">USD</span>
                  </p>

                  {paymentTokens?.length > 0 && (
                    <p className="small">
                      Accepts{" "}
                      {paymentTokens?.map((token, index) => {
                        return (
                          <a
                            href={`${toBlockExplorer("token", token?.address)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-reset d-inline-block"
                            key={index}
                          >
                            <span
                              className="badge me-1 mb-1 bg-secondary"
                              key={index}
                            >
                              {token?.symbol}
                            </span>
                          </a>
                        );
                      })}
                    </p>
                  )}

                  {!isPending ? (
                    <div className="mt-3">
                      <ClaimFundsHandler
                        customClass="btn btn-primary w-100"
                        endAt={currentProject?.endAt}
                        goal={currentProject?.goal}
                        pledged={currentProject?.pledged}
                        id={currentProject?.id}
                        creator={currentProject?.creator}
                        claimed={currentProject?.claimed}
                      />
                    </div>
                  ) : (
                    new Date().getTime() < currentProject?.startAt && (
                      <>
                        <div className="glass-bg p-4">
                          <h6 className="text-center mb-3">
                            Vote for the project
                          </h6>
                          <Countdown
                            date={currentProject?.startAt}
                            renderer={renderer}
                            onComplete={() => {
                              loadAllProjects(
                                projectContract,
                                projectContractAbi
                              );
                            }}
                          />

                          <VoteFundsHandler
                            customClass="btn btn-primary w-100"
                            endAt={currentProject?.endAt}
                            goal={currentProject?.goal}
                            pledged={currentProject?.pledged}
                            id={currentProject?.id}
                            creator={currentProject?.creator}
                            claimed={currentProject?.claimed}
                          />

                          {account === currentProject?.creator && (
                            <button
                              className="glassy-btn w-100 mt-3"
                              type="button"
                              onClick={cancelProjectHandler}
                            >
                              Cancel this project
                            </button>
                          )}
                        </div>
                      </>
                    )
                  )}

                  <div className="my-4">
                    <ProjectContributors id={currentProject?.id} />
                  </div>

                  {new Date().getTime() > currentProject?.startAt && (
                    <div className="glass-bg p-4">
                      {new Date().getTime() < currentProject?.endAt ? (
                        <>
                          <h6 className="text-center mb-3">
                            This Project is valid until
                          </h6>
                          <Countdown
                            date={currentProject?.endAt}
                            renderer={renderer}
                            onComplete={() => {
                              loadAllProjects(
                                projectContract,
                                projectContractAbi
                              );
                            }}
                          />
                        </>
                      ) : (
                        <p className="mb-0">This project has been ended</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default CampaingSinglePage;
