import React, { useMemo, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useContractWrite } from "wagmi";
import { waitForTransaction } from "@wagmi/core";
import Popup from "./Popup";
import Select from "react-select";
import Web3 from "web3";

// HOOKS
import useApp from "../../hooks/useApp";
import useProject from "../../hooks/useProject";

// TOKEN ABIs

function VotePopup() {
  const {
    projectId,
    paymentTokens,
    contract,
    abi,
    voteModalSrc,
    setVoteModalStatus,
    setTransactionLoading,
    setVoteCount,
  } = useApp();
  const { projectContract, projectContractAbi, loadAllProjects } = useProject();
  const [voteAmount, setVoteAmount] = useState(null);
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();





 
  const { write: web3Vote } = useContractWrite({
    address: projectContract?.address,
    abi: projectContract?.abi,
    functionName: "vote",
    onSuccess() {
      setTimeout(() => {
        setTransactionLoading(false);
        loadAllProjects(projectContract, projectContractAbi);
        setVoteCount();
        setVoteModalStatus(false);
        toast.success("Thank you for your vote!");
      }, 5000);
    },
    onMutate() {
      setTransactionLoading(true);
    },
    onError(error) {
      setTransactionLoading(false);
      toast.error("Oops! Something went error");
    },
  });

 

  function voteHandler(data) {
    setVoteAmount(Number(data?.amount));
    const formattedAmount = Number(data?.amount) ;
    web3Vote({
      args: [
        voteModalSrc?.id,
        formattedAmount.toString(),
      ],
    });
  }

  return (
    <>
      <Popup closeModal={setVoteModalStatus} containerClass="col-lg-6">
        <header className="text-center mb-4">
          <h2>Project Investment Voting Form</h2>
          <p className="text-muted">
            
          </p>
        </header>

        <form onSubmit={handleSubmit(voteHandler)}>
          <div className="row g-3">
            <div className="col-12">
              <label className="form-label" htmlFor="amount">
                Preferred investment amount (for voting purposes only):
              </label>
              <input
                type="number"
                step="0.1"
                className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                id="amount"
                placeholder="Enter voting amount in USD"
                name="amount"
                
                {...register("amount", {
                  required: {
                    value: true,
                    message: "Enter voting amount in USD",
                  },
                  min: {
                    value: 0.1,
                    message: "Please at least enter 0.1 USD",
                  },
                  max: {
                    value: 1000000,
                    message: "Max voting amount is 1000000 USD at a time",
                  },
                })}
              />

              <p className="mt-3">
                Non-binding Commitment
                <br />
                <span className="text-muted mt-3">
                  By submitting this form, you are indicating your interest in
                  the investment amount selected above. This is a non-binding
                  vote and does not obligate you to contribute financially.
                </span>
              </p>

              {errors.amount && (
                <span className="invalid-feedback">
                  {errors.amount?.message}
                </span>
              )}
            </div>

            <div className="col-12">
              <button className="btn btn-primary w-100" type="submit">
                Vote
              </button>
            </div>
          </div>
        </form>
      </Popup>
    </>
  );
}

export default VotePopup;
